import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const usePostStatus = () => {
  const toast = useToast();

  const getPostStatusList = async () => {
    try {
      return await store.dispatch('postStatus/getPostStatus');
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Post status not found',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    getPostStatusList,
  };
};

export default usePostStatus;
