<template>
  <b-card
    no-body
    v-if="dataLoaded"
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-start mb-75"
        >
          <label>Show</label>
          <v-select
            v-model="pageSize"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <label>entries</label>
        </b-col>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-start justify-content-sm-end"
        >
          <b-form-group>
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Press ENTER to search"
              @keyup.enter="refPostListTable.refresh()"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <div class="d-flex flex-md-row flex-column align-items-start justify-content-end w-100 px-1">
          <div class="d-flex d-flex-row">
            <flat-pickr
              v-model="searchByDate"
              class="form-control"
              :config="{ dateFormat: 'd/m/Y', enableTime: false }"
              readonly="false"
              placeholder="Date"
            />
            <b-button
              v-if="searchByDate !== ''"
              variant="flat-secondary"
              class="btn-icon ml-50"
              @click="clearSearchByDate"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </div>

          <v-select
            v-model="postStatusId"
            :reduce="(status) => status.id"
            :options="postStatusList"
            class="post-filter-select my-50 my-md-0 mx-md-1"
            placeholder="Select Status"
            label="name"
          >
            <template #selected-option="{ name }">
              <span class="text-truncate overflow-hidden">
                {{ name }}
              </span>
            </template>

            <template #option="{ name }">
              <span class="text-truncate overflow-hidden">
                {{ name }}
              </span>
            </template>
          </v-select>
        </div>
      </b-row>

    </div>

    <b-table
      ref="refPostListTable"
      :items="fetchPosts"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="orderBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="orderDesc"
      class="position-relative"
    >
      <template #cell(id)="data">
        <b-link
          :to="{
            name: 'post-detail',
            params: { id: data.item.id },
          }"
          class="font-weight-bold"
        >
          #{{ data.value }}
        </b-link>
      </template>

      <template #cell(accountId)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              variant="light-info"
            >
              <feather-icon
                size="16"
                icon="GridIcon"
              />
            </b-avatar>
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.account.name }}
          </span>
        </b-media>
      </template>

      <template #cell(postStatusId)="data">
        <b-badge
          :variant="`light-${getPostStatusVariant(data.item.postStatusId)}`"
          class="text-capitalize"
        >
          {{ data.item.postStatus.name }}
        </b-badge>
      </template>

      <template #cell(companyId)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              variant="light-secondary"
            >
              <feather-icon
                size="16"
                icon="BriefcaseIcon"
              />
            </b-avatar>
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.company.name }}
          </span>
        </b-media>
      </template>

      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          right
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item
            v-if="data.item.postStatusId !== POST_STATUS_EDITED_ID"
            @click="$router.push({
              name: 'post-edit',
              params: { id: data.item.id },
            })"
          >
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer mr-50"
              size="16"
            />
            <span>Edit</span>
          </b-dropdown-item>

          <b-dropdown-item @click="$router.push({
            name: 'post-detail',
            params: { id: data.item.id },
          })">
            <feather-icon
              icon="EyeIcon"
              size="16"
              class="mr-50"
            />
            <span>Preview</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="data.item.postStatusId !== POST_STATUS_EDITED_ID"
            @click="changeStatus(data.item.id)"
          >
            <feather-icon
              icon="DollarSignIcon"
              size="16"
              class="mr-50"
            />

            <span>Change Status</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-center justify-content-md-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-center justify-content-md-end"
        >
          <b-pagination
            v-model="pageNumber"
            :total-rows="totalPosts"
            :per-page="pageSize"
            first-number
            last-number
            class="mb-0 mt-1 mt-md-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { avatarText } from '@core/utils/filter';
import { BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormGroup, BFormInput, BLink, BMedia, BPagination, BRow, BTable, BTooltip } from 'bootstrap-vue';
import { ref } from 'vue-demi';
import flatPickr from 'vue-flatpickr-component';
import Swal from 'sweetalert2';
import usePost from '@/modules/post/composables/usePost';
import usePostList from '@/modules/post/composables/usePostList';
import usePostStatus from '@/modules/post/composables/usePostStatus';
import usePostUtils from '@/modules/post/composables/usePostUtils';
import vSelect from 'vue-select';

export default {
  setup() {
    const {
      postStatusId,
      dataMeta,
      fetchPosts,
      orderBy,
      orderDesc,
      pageNumber,
      pageSize,
      perPageOptions,
      refPostListTable,
      tableColumns,
      searchQuery,
      totalPosts,
      searchByDate,
      clearSearchByDate,
      refreshTable,
    } = usePostList();

    const { POST_STATUS_EDITED_ID, getPostStatusVariant } = usePostUtils();
    const { getPostStatusList } = usePostStatus();
    const { changePostStatus } = usePost();

    const postStatusList = ref([]);
    const dataLoaded = ref(false);

    const initData = async () => {
      const statusList = await getPostStatusList();
      postStatusList.value = statusList;
    }

    const changeStatus = async (postId) => {
      const { value } = await Swal.fire({
        title: 'Change Post Status',
        text: 'This action will change the status of all linked transactions',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, continue!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!value) return;

      await changePostStatus(postId);
      refreshTable();
    }

    initData().then(_ => dataLoaded.value = true);

    return {
      // Const
      POST_STATUS_EDITED_ID,

      avatarText,
      postStatusId,
      dataLoaded,
      dataMeta,
      fetchPosts,
      getPostStatusVariant,
      orderBy,
      orderDesc,
      pageNumber,
      pageSize,
      perPageOptions,
      refPostListTable,
      searchQuery,
      postStatusList,
      tableColumns,
      totalPosts,
      searchByDate,
      clearSearchByDate,
      changeStatus,
    };
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    flatPickr,
    vSelect,
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.post-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
