import { ref, watch, computed } from '@vue/composition-api';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useUtils from '@/composables/useUtils';
import usePost from '@/modules/post/composables/usePost';

export default function usePostList() {
  const toast = useToast();
  const { formatCurrency, convertUtcToLocalDate, formatDateDdMmYyyyHi } = useUtils();
  const { getPosts } = usePost();

  const perPageOptions = [10, 25, 50, 100];
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'accountId', label: 'account', sortable: true },
    { key: 'postStatusId', label: 'status' },
    { key: 'companyId', label: 'company', sortable: true },
    {
      key: 'postTotalFee',
      label: 'total',
      sortable: true,
      formatter: (val) => formatCurrency(val),
    },
    {
      key: 'dateAddedUtc',
      label: 'date added',
      sortable: true,
      sortable: true,
      formatter: (val) => `${formatDateDdMmYyyyHi(convertUtcToLocalDate(val))}`,
    },
    { key: 'actions' },
  ];

  const postStatusId = ref(null);
  const pageNumber = ref(1);
  const orderDesc = ref(true);
  const pageSize = ref(10);
  const refPostListTable = ref(null);
  const orderBy = ref('id');
  const totalPosts = ref(0);
  const searchQuery = ref(null);
  const searchByDate = ref('');

  const dataMeta = computed(() => {
    const localItemsCount = refPostListTable.value
      ? refPostListTable.value.localItems.length
      : 0;
    return {
      from: pageSize.value * (pageNumber.value - 1) + (localItemsCount ? 1 : 0),
      to: pageSize.value * (pageNumber.value - 1) + localItemsCount,
      of: totalPosts.value,
    };
  });

  watch([pageNumber, pageSize, postStatusId, searchByDate], () => {
    refPostListTable.value.refresh();
  });

  const refreshTable = () => {
    refPostListTable.value.refresh();
  };

  const clearSearchByDate = () => {
    searchByDate.value = '';
  };

  const fetchPosts = (_, callback) => {
    const params = {
      postStatusId: postStatusId.value,
      searchByDate: searchByDate.value === '' ? null : searchByDate.value,
      pageSize: pageSize.value,
      pageNumber: pageNumber.value,
      orderBy: orderBy.value,
      orderDesc: orderDesc.value,
      searchQuery: searchQuery.value,
    };

    getPosts(params)
      .then((response) => {
        const { posts, total } = response;
        callback(posts);
        totalPosts.value = total;
      })
      .catch(() => {
        toast.error({
          component: ToastificationContent,
          props: {
            title: 'Error fetching posts list',
            icon: 'AlertTriangleIcon',
          },
        });
      });
  };

  return {
    postStatusId,
    dataMeta,
    fetchPosts,
    orderBy,
    orderDesc,
    pageNumber,
    pageSize,
    perPageOptions,
    refPostListTable,
    searchQuery,
    tableColumns,
    totalPosts,
    searchByDate,
    clearSearchByDate,
    refreshTable,
  };
}
