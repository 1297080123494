import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const usePost = () => {
  const toast = useToast();

  const createPost = async (post) => {
    try {
      return await store.dispatch('post/create', post);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const getPosts = async (filter) => {
    try {
      return await store.dispatch('post/getPosts', filter);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const getPost = async (id) => {
    try {
      return await store.dispatch('post/getPost', id);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const updatePost = async (post) => {
    try {
      return await store.dispatch('post/update', post);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };
  const changePostStatus = async (postId) => {
    try {
      await store.dispatch('post/changePostStatus', postId);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const downloadPost = async (postId) => {
    try {
      return await store.dispatch('post/downloadPost', postId);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  return {
    // Method
    changePostStatus,
    createPost,
    getPost,
    getPosts,
    updatePost,
    downloadPost,
  };
};

export default usePost;
