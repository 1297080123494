import useUtils from '@/composables/useUtils';
import store from '@/store';

const usePostUtils = () => {
  const { formatDecimalNumber } = useUtils();

  const POST_STATUS_PAID_ID = 1;
  const POST_STATUS_PENDING_ID = 2;
  const POST_STATUS_EDITED_ID = 3;

  const storePost = (post) => {
    store.commit('post/storePost', {
      ...store.getters['post/post'],
      ...post,
    });
  };

  const getAccountCommission = (post) => {
    const totalTaxiRates = post.postTaxis.reduce(
      (total, taxi) => total + taxi.taxiRate,
      0
    );
    return formatDecimalNumber(post.postTotalFee - totalTaxiRates);
  };

  const getPostStatusVariant = (id) => {
    if (id === POST_STATUS_PAID_ID) return 'success';
    if (id === POST_STATUS_PENDING_ID) return 'warning';
    return 'secondary';
  };

  return {
    // Conts
    POST_STATUS_PAID_ID,
    POST_STATUS_PENDING_ID,
    POST_STATUS_EDITED_ID,

    // Method
    getPostStatusVariant,
    getAccountCommission,
    storePost,
  };
};

export default usePostUtils;
